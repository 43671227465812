import styled, { css } from "styled-components";

import { fadeIn, fadeOut } from "../../../utils/animations";
import { device } from "../../../utils/breakpoints";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #151515;
  background: #ffffff;
`;

export const BackArrowWrapper = styled.div`
  margin: 120px 24px 0;

  svg {
    height: 36px;
  }

  &:hover {
    cursor: pointer;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin: 88px 64px 0 88px;

    svg {
      height: auto;
    }
  }

  @media ${device.computer} {
    margin: 80px 64px 0 64px;

    svg {
      height: auto;
    }
  }

  @media ${device.desktop} {
    margin: 128px 120px 0 120px;
  } ;
`;

export const Title = styled.h2`
  padding: 42px 24px 0;
  margin: 0;
  font-size: 32px;
  line-height: 1.08;
  font-weight: 800;
  color: #151515;

  @media ${device.mobile} {
    color: #013cff;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    font-size: 48px;
    line-height: 64px;
    padding: 80px 0 0 88px;
  }

  @media ${device.computer} {
    font-size: 48px;
    line-height: 74px;
    padding: 80px 64px 0 64px;
  }

  @media ${device.laptop} {
    font-size: 52px;
    line-height: 76px;
  }

  @media ${device.desktop} {
    padding: 128px 120px 0 120px;
    font-size: 60px;
    line-height: 82px;
  } ;
`;

export const MobileNavigationBar = styled.div`
  height: 72px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 16px 4px rgba(29, 29, 27, 0.04);
`;

export const SectionWrapper = styled.div`
  width: 80%;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.computer} {
    width: 65%;
  } ;
`;

export const SectionTitle = styled.h2`
  padding: 36px 24px 0;
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #151515;

  @media ${device.tablet}, ${device.tabletLandscape} {
    font-size: 28px;
    line-height: 36px;
    padding: 52px 32px 0 88px;
  }

  @media ${device.computer} {
    font-size: 28px;
    line-height: 36px;
    padding: 52px 32px 0 64px;
  }

  @media ${device.laptop} {
    font-size: 32px;
    line-height: 38px;
  }

  @media ${device.desktop} {
    padding: 80px 64px 0 120px;
    font-size: 36px;
    line-height: 42px;
  } ;
`;

export const Description = styled.div`
  padding: 0 24px;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
  color: #151515;

  h6 {
    line-height: 18px;
    font-weight: 400;
  }

  a {
    color: #013cff;
    font-weight: 600;
    text-decoration: none;
  }

  *:not(h6) {
    font-size: inherit;
    line-height: inherit;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin: 6px 0;
    padding-left: 88px;
    padding-right: 0;
  }

  @media ${device.computer} {
    margin: 6px 0;
    padding-left: 64px;
    padding-right: 0;
  }

  @media ${device.desktop} {
    margin: 6px 0;
    padding-left: 120px;
    padding-right: 0;
  } ;
`;

export const HorizontalDivider = styled.div`
  width: 96px;
  height: 8px;
  margin: 24px 0;
  background: #013cff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 144px;
    margin: 24px 88px 32px 88px;
    min-height: 8px;
  }

  @media ${device.computer} {
    width: 144px;
    min-height: 8px;
    margin: 40px 64px;
  }

  @media ${device.desktop} {
    width: 144px;
    min-height: 8px;
    margin: 30px 0 30px 120px;
  } ;
`;

export const FundsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 32px 48px;

  & > * {
    margin: 16px 24px;
    max-width: calc(100% - 24px);
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    flex-direction: row;
    padding: 32px 0;

    & > * {
      margin: 0 33px;
      max-width: calc(33% - 24px);
    }

    & > *:first-child {
      margin-left: 88px;
      max-width: calc(33% - 24px);
    }
  }

  @media ${device.computer} {
    flex-direction: row;
    padding: 32px 0;
    & > *:first-child {
      margin-left: 64px;
    }
  }

  @media ${device.desktop} {
    padding: 32px 0;
    flex-direction: row;
    & > * {
      margin: 0 48px;
    }

    & > *:first-child {
      margin-left: 120px;
    }
  } ;
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  width: 50%;
  height: 137px;
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: 14% 42% 12% 32%;
  grid-template-rows: 10% 16% 16% 16% 32% 10%;

  @media ${device.mobileS} {
    height: 153px;
  }

  @media ${device.tablet} {
    width: 44%;
    height: 32%;
  }

  @media ${device.tabletLandscape} {
    width: 36%;
    height: 50%;
  }

  @media ${device.computer}, ${device.desktop} {
    width: 32%;
    height: 58%;
  } ;
`;

interface BlueReactangleProps {
  column?: string;
  row?: string;
}

export const BlueRectangle = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  grid-column: ${(props: BlueReactangleProps) => props.column || "auto / auto"};
  grid-row: ${(props: BlueReactangleProps) => props.row || "auto / auto"};
  background-color: #013cff;
`;

export const FooterWrapper = styled.div`
  margin: 0 0 0 24px;
  display: flex;
  flex-direction: column;

  @media ${device.tablet}, ${device.tabletLandscape} {
    flex-direction: row;
    margin-left: 88px;
  }

  @media ${device.computer} {
    flex-direction: row;
    margin-left: 64px;
  }

  @media ${device.desktop} {
    flex-direction: row;
    margin-left: 120px;
  } ;
`;

export const BackToTopArrowWrapper = styled.div`
  min-height: 68px;
  margin: 0 24px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  svg {
    cursor: pointer;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin: 32px 51px 0 0;
  }

  @media ${device.computer} {
    margin: 32px 64px 0 0;
  }

  @media ${device.desktop} {
    margin: 50px 120px 0 0;
  } ;
`;

export const ContactInformation = styled.div`
  width: auto;
  height: 100%;
  margin: 0;

  &:first-of-type {
    margin-right: 4vw;
  }

  @media ${device.mobile} {
    &:first-of-type {
      margin: 27px 0 0;
    }
  } ;
`;

export const ContactTitle = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #151515;
`;

export const ContactDescription = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
  white-space: pre-line;

  @media ${device.mobile} {
    margin-bottom: 32px;
  } ;
`;

interface PageProps {
  animation?: string;
}

export const TransitionWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-template-rows: repeat(4, auto);
  z-index: 1000;
  pointer-events: none;

  & > * {
    animation-name: ${(props: PageProps) =>
      props.animation
        ? css`
            ${fadeIn}
          `
        : css`
            ${fadeOut}
          `};
    animation-timing-function: linear;
  }
`;

interface TransitionBlockProps {
  delay: number;
}

export const TransitionRandomBlock = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #ffffff;
  z-index: 1;
  opacity: 0;
  animation-duration: ${(props: TransitionBlockProps) => `${props.delay}ms`};
  animation-fill-mode: forwards;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 48px 32px 48px;

  @media ${device.mobile} {
    flex-direction: column;
  }

  @media ${device.desktop} {
    margin: 30px 0 0 120px;
  } ;
`;

export const Button = styled.button`
  border: none;
  background: #013cff;
  color: white;
  padding: 8px 18px;
  border-radius: 5px;
  font-weight: bold;

  &:hover {
    color: #151515;
    transition: 0.3s ease-in-out;
  }
`;
